import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d37e252"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "emailpasswordContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailForm = _resolveComponent("EmailForm")!
  const _component_PasswordForm = _resolveComponent("PasswordForm")!
  const _component_NotificationForm = _resolveComponent("NotificationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EmailForm, { id: "emailFormContainer" }),
    _createVNode(_component_PasswordForm, { id: "passwordFormContainer" }),
    _createVNode(_component_NotificationForm, { id: "notifFormContainer" })
  ]))
}