import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbe03602"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "notifFormContainer" }
const _hoisted_2 = {
  id: "notificationSwitch",
  class: "form"
}
const _hoisted_3 = { id: "notificationSwitchLabel" }
const _hoisted_4 = {
  id: "notificationSelect",
  class: "form"
}
const _hoisted_5 = { id: "notificationSelectLabel" }
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchInput = _resolveComponent("SwitchInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('EDIT_NOTIFICATION')), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABEL_NOTIFICATION')), 1 /* TEXT */)
      ]),
      _createVNode(_component_SwitchInput, {
        modelValue: _ctx.notificationsEnabled,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notificationsEnabled) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABEL_NOTIFICATION_TYPE')), 1 /* TEXT */)
      ]),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNotifType) = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifTypes, (n) => {
          return (_openBlock(), _createElementBlock("option", {
            value: n.value,
            key: n.value
          }, _toDisplayString(n.label), 9 /* TEXT, PROPS */, _hoisted_6))
        }), 128 /* KEYED_FRAGMENT */))
      ], 512 /* NEED_PATCH */), [
        [_vModelSelect, _ctx.selectedNotifType]
      ])
    ], 512 /* NEED_PATCH */), [
      [_vShow, _ctx.notificationsEnabled]
    ]),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateNotifications())),
      class: "submitButton"
    }, _toDisplayString(_ctx.$t('SAVE').toUpperCase()), 1 /* TEXT */)
  ]))
}